.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}

/* About Bailamigos Section */
.aboutText {
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1000px;
  text-align: left;
  font-size: 1.3rem;
  line-height: 1.8;
  font-family: 'Lato', sans-serif;
  padding: 25px;
  border-radius: 21px;
  background-color: rgba(0, 0, 0, 0.9);
  margin: 2rem auto;
  border: #f5f5f5 solid;
}

/* Instructor Section */
.instructors {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensures cards wrap when necessary */
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
  box-sizing: border-box;
}

/* Instructor Card */
.instructorCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1 1 calc(50% - 20px); /* 2 cards per row on larger screens */
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: #f5f5f5 solid;
  box-sizing: border-box;
}

.instructorImage {
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.instructorText {
  margin-top: 10px;
  font-size: 1rem;
  color: #ffffff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .instructors {
    flex-direction: column;
    align-items: center;
  }

  .instructorCard {
    flex: 1 1 100%; /* Full width for each card on smaller screens */
  }

  .aboutText {
    font-size: 1.1rem;
    padding: 20px;
    max-width: 600px;
  }
}

@media (max-width: 480px) {
  .aboutText {
    font-size: 1rem;
    padding: 15px;
    max-width: 400px;
  }

  .instructorCard {
    flex: 1 1 100%; /* Stack the cards in a column on mobile */
  }
}
