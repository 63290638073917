/* Fuerza Text Styling */
.fuerzaText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  max-width: 750px;
  text-align: left;
  font-size: 1.25rem;
  font-family: 'Lato', sans-serif;
  padding: 25px;
  border-radius: 21px;
  color: aliceblue;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 2rem auto;
}

/* Responsive Styles for Tablet */
@media (max-width: 768px) {
  .fuerzaText {
    font-size: 1.1rem; 
    padding: 20px;
    max-width: 600px;
  }
}

/* Responsive Styles for Mobile */
@media (max-width: 480px) {
  .fuerzaText {
    font-size: 1rem;
    padding: 15px;
    max-width: 400px;
  }
}
  