/* Header */
header {
  background-color: transparent;
  color: white;
  padding: 1rem 1.5rem;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px; /* Fixed height for the header */
  z-index: 1000;
  display: flex;
  justify-content: space-between; /* Space between nav links and hamburger */
  align-items: center;
}

/* Navigation */
.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  cursor: pointer;
  font-size: 1.8rem; /* Icon size */
  color: white;
  background: none;
  border: none;
  margin-left: auto; /* Ensure it's aligned to the right */
  z-index: 1001; /* Keep it above other content */
}

/* Mobile Menu */
.mobile-menu {
  display: none;
  flex-direction: column;
  position: absolute; /* Position menu absolutely to avoid pushing header */
  top: 60px; /* Positioned right below the header */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 1rem;
  border-radius: 10px;
  z-index: 999; /* Ensure it's below the hamburger menu */
}

/* Show menu links stacked vertically */
.mobile-menu a {
  display: block;
  margin: 1rem 0;
  font-size: 1.5rem;
  text-align: center;
}

.mobile-menu.open {
  display: flex;
}

/* Desktop Menu */
.menuBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.menuBar a {
  color: white;
  background-color: rgb(23, 68, 108);
  font-size: large;
  margin: 0 1rem;
  text-decoration: none;
  border: 3px solid white;
  border-radius: 15px;
  padding: 0.3rem 1rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menuBar {
    display: none; /* Hide desktop menu on smaller screens */
    border-radius: 0;
  }

  .hamburger {
    display: block;
    font-size: 1.8rem; /* Icon size */
    color: white;
    background: none;
    border: none;
    outline: none;
    margin-left: auto; /* Keep it to the right with flexbox */
    margin-right: 6%;
    z-index: 1001;
  }

  .hamburger:focus, .hamburger:hover, .hamburger:active {
    background: none; /* Ensure no background color change on hover or click */
    outline: none; /* Remove focus outline */
    box-shadow: none; /* Remove any box-shadow effect if present */
  }
}


/** Images **/

/* General Image Styling */
.headerLogo {
  max-width: 8%; /* Ensure image doesn't overflow its container */
  height: auto; /* Maintain aspect ratio */
}

/* Thumbnail Images */
.thumbnail {
  width: 150px; /* Fixed size for thumbnails */
}

/* Medium Images */
.medium {
  max-width: 300px; /* Limit max width */
  height: auto; /* Maintain aspect ratio */
}

/* Large Images */
.large {
  max-width: 600px; /* Limit max width */
  height: auto; /* Maintain aspect ratio */
}

/* Full-Width Images */
.full-width {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .headerLogo {
    max-width: 15%; /* Ensure image doesn't overflow its container */
    height: auto; /* Maintain aspect ratio */
  }
  .thumbnail {
    width: 100px; /* Smaller thumbnail size on mobile */
    height:auto;
  }

  .medium {
    max-width: 80%; /* Use a percentage for responsiveness */
  }

  .large {
    max-width: 100%; /* Full width on smaller screens */
  }
}
