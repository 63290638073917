/* App.css */

/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
  width: 100%;
  height: 100%; /* Ensure body takes full height */
  font-family: 'Raleway', sans-serif;
  color: #333;
  background-image: url('/public/Images/background.jpg'); /* Correctly reference the image in the public folder */
  background-size: cover; /* Cover the entire body */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-attachment: fixed; /* Keep the background fixed */
}

h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}

ul{
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 2.1; /* Line height multiplier */
  /* word-spacing: 0.3rem; Mellanrum mellan ord */
}

li{
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 2.1; /* Line height multiplier */
  /* word-spacing: 0.3rem; Mellanrum mellan ord */
}

p {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 2.1; /* Line height multiplier */
  word-spacing: 0.3rem; /* Mellanrum mellan ord */
}

/* Ensure root element takes full height */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure root element takes full height */
}

/* Main App Content */
.app-content {
  padding: 20px;
  padding-top: 6%;
  flex: 1;
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  flex-direction: column; /* Ensures content is stacked vertically */
  overflow-y: auto; /* Allows vertical scrolling if content is too long */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box; /* Ensure padding doesn’t add extra width */
}

/* Header and Footer */
header, footer {
  background-color: rgba(0, 0, 0, 0.5); /* Partially transparent background */
  color: white;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

footer {
  margin-top: auto; /* Push footer to the bottom */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .app-content {
    padding-top: 15%;
  }

  header nav a, footer a {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .app-content {
    padding-top: 26%;
  }

  header, footer {
    padding: 15px;
  }

  header nav a, footer a {
    font-size: 0.9rem;
  }
}