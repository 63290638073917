/* Ensure the form takes full width of the container */
form {
  display: flex;
  flex-direction: column;
  max-width: 500px; /* Ensure the form doesn't get too narrow */
  width: 100%;
  box-sizing: border-box; /* Ensures padding and border are included in width */
  background-color: #333;
  padding: 30px;
  border-radius: 12px;
  justify-self: center;
  margin: 0 auto; /* Centers the form on the page */
}

/* Label styling */
.LabelParAnmälan {
  color: #ce8c42;
}

/* Adjust each form element container */
form div {
  display: flex;
  flex-direction: column; /* Ensure each label and input is in a column */
  margin-bottom: 15px;
}

.kursSeparation {
  border-top: #ce8c42 solid;
}

.forarefoljare {
  color:rgb(188, 135, 67)
}

/* Label styling */
label {
  margin-bottom: 5px; /* Adds space below the label */
  font-weight: bold;
  text-align: left; /* Ensures label text aligns to the left */
  color: #fff;
}

/* Input and select styling */
input[type="text"],
input[type="email"],
select {
  padding: 10px;
  border: 1px solid #444444;
  border-radius: 4px;
  font-size: 16px;
  width: 100%; /* Ensure the input takes up full width */
  box-sizing: border-box; /* Includes padding and border in element's total width */
  color: white;
  background-color: #444444;
}

/* Focus styles for input and select */
input[type="text"]:focus,
input[type="email"]:focus,
select:focus {
  border-color: #4CAF50;
  outline: none;
}

/* Button styling */
form button {
  margin-top: 30px;
  padding: 10px 15px;
  background-color: #4CAF50;
  color: rgb(223, 223, 223);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
  align-self: center; /* Centers the button horizontally within the form */
}

form button:hover {
  background-color: #45a049;
}

/* Radio group styling */
.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between radio options */
}

.radio-label {
  position: relative;
  padding-left: 30px; /* Space for custom radio */
  cursor: pointer;
  font-size: 16px;
  color: #ffffff; /* Text color */
  user-select: none; /* Prevent text selection on click */
}

.radio-label input {
  opacity: 0; /* Hide the default radio button */
  position: absolute; /* Positioning for accessibility */
  cursor: pointer;
}

.custom-radio {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px; /* Size of custom radio */
  height: 20px;
  border: 2px solid #007BFF; /* Border color */
  border-radius: 50%; /* Make it circular */
  background-color: white; /* Background color */
  transition: background 0.3s ease, border-color 0.3s ease; /* Smooth transition */
}

.radio-label input:checked + .custom-radio {
  background-color: #007BFF; /* Background color when checked */
  border-color: #007BFF; /* Border color when checked */
}

.custom-radio::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px; /* Size of inner dot */
  height: 10px;
  border-radius: 50%;
  background: white; /* Inner dot color */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.radio-label input:checked + .custom-radio::after {
  opacity: 1; /* Show inner dot when checked */
}

/* Media Queries for Responsive Design */

/* For tablets and larger mobile devices */
@media (max-width: 768px) {
  form {
    max-width: 90%; /* Reduce max-width for smaller screens */
    padding: 20px;
  }

  input[type="text"],
  input[type="email"],
  select {
    font-size: 14px;
  }

  form button {
    padding: 8px 12px;
  }
}

/* For mobile phones */
@media (max-width: 480px) {
  form {
    max-width: 90%; /* Full width for mobile screens */
    padding: 15px;
  }

  label {
    font-size: 14px;
  }

  input[type="text"],
  input[type="email"],
  select {
    font-size: 14px;
    padding: 8px;
  }

  form button {
    font-size: 14px;
    padding: 10px 12px;
  }
}