/* Footer.css */
footer {
  text-align: center;
  padding: 1rem;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.footerText {
  margin: 0;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
}

.social-buttons {
  margin-top: 1rem;
}

.social-button {
  background: none;
  border: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  margin: 0 15px;
  transition: transform 0.3s;
}

.social-button:hover {
  transform: scale(1.1);
  background: none;
  border: none;
}

@media (max-width: 768px) {
  .footerText {
    font-size: 1rem;
  }

  .social-button {
    margin: 0 3px;
  }
}

@media (max-width: 480px) {
  .footerText {
    font-size: 0.9rem;
  }

  .social-button {
    margin: 0 1px;
  }
}
