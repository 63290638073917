.courses-container {
  padding: 1%;
  max-width: 100%;
  margin: 0 auto;
}

h2 {
  text-align: center;
  font-size: 2.8rem;
  color: #1f2937;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  h2 {
    font-size: 2.2rem;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.8rem;
  }
}
