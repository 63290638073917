/* Modern card-based layout for courses */
.course-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
}

/* Style for each section */
.current-courses, .upcoming-courses {
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, c0, 0.08); /* Modern shadow for depth */
}

.course-list{
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
  justify-content: space-around;
}

/* Course title */
.course-list-title {
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  color: #fcfcfc;
  margin-bottom: 20px;
  border-bottom: 3px solid #ffa726;
  background-color: rgba(0, 0, 0, .4); /* Partially transparent background */
}

/* Course item */
.course-item {
  margin-bottom: 0px;
  padding: 20px;
  border-radius: 12px;
  /* border: #f5f0f0 solid; */
  color: #fcfcfc;
  background-color: rgba(0, 0, 0, .8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

/* Day header */
.course-item h5 {
  font-size: 1.6rem;
  color: #f5f0f0;
  margin-bottom: 15px;
  margin-top: 5px;
}

.course-detail{
  padding: 1%;
  justify-content: center;
  align-items: center;
  margin-bottom: 3%;
  font-size: x-large;
  font-weight: 500;
  line-height: 180%;
  border-radius: 1em;
  border: #f5f0f0 solid;
}

/* Date circles container */
.date-container {
  display: flex;
  flex-wrap: wrap; /* Allow dates to wrap to the next line if necessary */
  gap: 8px; /* Space between circles */
  margin-top: 1.5%;
  max-width: 100%; /* Ensure it doesn't overflow */
}

/* Style for date circles */
.date-circle {
  border: #fcfbfb solid;
  color: rgb(247, 241, 241);
  padding: 6px 10px;
  border-radius: 50%; /* Makes the element circular */
  font-size: 0.9rem;
  font-weight: 600;
  min-width: 30px; /* Minimum width to maintain circular shape */
  height: 30px; /* Set a fixed height for circles */
  display: flex; /* Enable flexbox for centering */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  white-space: nowrap; /* Prevents text from wrapping inside circles */
}

/* Responsive Styles for Date Container */
@media (max-width: 768px) {
  .date-circle {
    padding: 4px 8px; /* Reduce padding on smaller screens */
    font-size: 0.8rem; /* Slightly smaller font size for better fit */
  }
  
.course-detail{
  font-size: large;
  padding: 3%;

}
}

@media (max-width: 480px) {
  .date-circle {
    padding: 3px 6px; /* Further reduce padding for mobile */
    font-size: 0.7rem; /* Even smaller font size for mobile */
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .course-item {
    flex: 1 1 calc(50% - 20px); /* 2 items per row on tablet */
  }

  .course-list-title {
    font-size: 2rem; /* Slightly smaller title on tablet */
  }
}

@media (max-width: 480px) {
  .course-item {
    flex: 1 1 100%; /* Full width on mobile */
  }

  .course-list-title {
    font-size: 1.5rem; /* Smaller title on mobile */
  }
  .course-detail{
    font-size: large;
  }
}