/* ContactForm.css*/
form {
  display: flex;
  flex-direction: column;
  width:100%;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.71); /* Partially transparent background */
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 1rem auto;
  border-radius: 1em;
  border: #fff solid;
}

.elementForm {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.from_email {
  color: darkgreen;
}

.success {
  color: darkgreen;
}

.error {
  color: red;
}

label {
  font-size: 1.2rem;
  font-weight: bold;
  color: #b3b3b3;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

input, textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #4b4b4b;
  border-radius: 6px;
  font-size: 1rem;
  color: white;
  background-color: #2e2e2e;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

input:focus, textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
  outline: none;
}

textarea {
  height: 150px;
  resize: vertical;
}

button {
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  font-size: 1.2rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

button:active {
  background-color: #003d80;
  transform: translateY(0);
}

/* Responsive design */
@media (max-width: 768px) {
  form {
    padding: 1.5rem;
    max-width: 90%; /* Use a percentage for width on tablets */
    min-width: 100%; /* Allow the form to take full width */
  }

  button {
    font-size: 1.1rem; /* Slightly reduce button size */
  }
}

@media (max-width: 600px) {
  form {
    padding: 1rem;
    max-width: 100%; /* Allow form to use full screen width */
    min-width: auto; /* Remove minimum width */
  }

  input, textarea {
    font-size: 0.9rem; /* Reduce font size for inputs on small screens */
    padding: 0.6rem;
  }

  button {
    font-size: 1rem; /* Further reduce button size for mobile */
    padding: 0.6rem;
  }
}

@media (max-width: 480px) {
  form {
    padding: 0.75rem;
  }

  button {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}