.registered-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .course-section {
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .course-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .participant-list {
    list-style: none;
    padding: 0;
  }
  
  .participant-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .participant-item:last-child {
    border-bottom: none;
  }
  
  .empty-message {
    font-style: italic;
    color: #888;
  }
  