.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  /* General Video Player Styling */
  .video-player {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
  
  /* Horizontal Video Player */
  .horizontal-video .video-player {
    width: 100%;
    max-width: 800px;
    height: auto;
  }
  
  /* Vertical Video Player */
  .vertical-video .video-player {
    width: 100%;
    max-width: 200px; /* Adjust the width for vertical videos */
    height: auto;
  }  