/* Registration.css */
h2 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  
  @media (max-width: 768px) {
    h2 {
      font-size: 1.75rem;
    }
  }
  
  @media (max-width: 480px) {
    h2 {
      font-size: 1.5rem;
    }
  }
  