.album-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 10px; /* Gap between images */
  padding: 20px;
}

.album-item {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for each image */
  border: white solid;

}

.albumImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}

/* Responsive Design */
@media (max-width: 768px) {
  .album-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
  }
}

@media (max-width: 480px) {
  .album-container {
    grid-template-columns: 1fr; /* 1 column on mobile */
  }
}
