.countdown-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  flex-wrap: nowrap; /* Ensure items stay in a single row */
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
  padding: 10px 0;
}

.countdown-item {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 70px;
  margin: 5px;
}

.countdown-number {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  color: #d9534f;
}

.countdown-label {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #666;
}

.countdown-ended {
  text-align: center;
  font-size: 1.5rem;
  color: #d9534f;
}

/* Responsive Design */
@media (max-width: 768px) {
  .countdown-container {
    gap: 10px;
  }
  
  .countdown-item {
    padding: 8px;
    min-width: 60px;
  }

  .countdown-number {
    font-size: 1.5rem;
  }

  .countdown-label {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .countdown-container {
    gap: 5px; /* Adjust spacing for smaller screens */
  }

  .countdown-item {
    min-width: 50px; /* Ensure readability on narrow screens */
  }

  .countdown-number {
    font-size: 1.3rem;
  }

  .countdown-label {
    font-size: 0.65rem;
  }
}