/* Home Container */
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal overflow */
  margin-top: 30px;
}

/* Home Text */
.home-text {
  font-family: 'Glacial Indifference', sans-serif; /* Modern, clean font */
  line-height: 1.6; /* Better readability */
  color: #333; /* Neutral text color */
  font-size: 1rem; /* Base font size */
  margin: 20px auto; /* Center alignment */
  max-width: 800px; /* Limit line width for readability */
  text-align: left; /* Keep alignment natural */
}

/* Styling strong tags for emphasis */
.home-text strong {
  color: #ffa726; /* Accent color */
  font-weight: bold; /* Highlight important sections */
}

/* Adding bullets for lists */
.home-text ul {
  margin: 15px 0;
  padding-left: 20px; /* Indent list for clarity */
}

.home-text li {
  margin-bottom: 10px;
  line-height: 1.5;
  list-style-type: disc; /* Classic bullet points */
}

/* Description Section */
.description {
  margin-top: 20px;
  max-width: 90%;
  padding: 3%;
}


/*************/
/* Header */
.tajmer {
  display: flex;
  flex-direction: column; /* Stack text and timer in a column */
  align-items: center; /* Center align the content */
  height:fit-content;
}

.banner-bild{
  max-width: 100%;
}

/* Home Text */
.text-big {
  font-size: 2rem;
  margin: 0;
  color:aliceblue;
  font-weight: 700;
}

.section-title{
  color:aliceblue;
}

.home-text{
  color:aliceblue;
}

.offer-list{
  color:aliceblue;
}

/* Description Text */
.description-text {
  margin-top: 3rem;
  background: rgba(0, 0, 0, .8);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgb(0, 0, 0); /* Shadow for depth */
  max-width: 80%;
  font-size: 1.3rem;
  line-height: 1.8;
  text-align: justify;
  color: #e0e0e0;
  border: #f5f5f5 solid;
}

/* Video Row */
.videoRow {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  width: 100%;
  max-width: 1200px; /* Max width for the container */
  box-sizing: border-box;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-text h1 {
    font-size: 3rem; /* Smaller heading on tablets */
  }

  .description-text {
    font-size: 1.1rem; /* Smaller description text on tablets */
    max-width: 90%; /* Increase width on smaller screens */
  }

  /* Stack video row vertically on mobile */
  .videoRow {
    flex-direction: column; /* Stack videos vertically on mobile */
    align-items: center; /* Center align items */
  }

  /* This rule will ensure each video takes full width on mobile */
  .videoRow > * {
    width: 100%; /* Set all videos to full width on mobile */
    margin-bottom: 20px; /* Space between videos */
  }
}

@media (max-width: 480px) {
  .home-text h1 {
    font-size: 2.5rem; /* Even smaller heading on mobile */
  }

  .description-text {
    font-size: 1rem; /* Smaller description text on mobile */
    padding: 20px; /* Adjust padding for mobile */
  }
}